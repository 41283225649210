import classNames from 'classnames';
import { useRef } from 'react';

interface TransformationProps {
  path: string;
  compress?: number;
  width: number;
  height?: number;
  sharpen?: boolean;
  faces?: boolean;
  entropy?: boolean;
}

export function build_responsive_url(props: TransformationProps, dpr = 1) {
  const url = new URL(props.path.includes('https') ? props.path : `https://optimize.photology.co/${props.path}`);

  const compress = props.compress || 90;

  url.searchParams.set('q', compress.toString());
  url.searchParams.set('auto', 'format,compress');
  url.searchParams.set('dpr', dpr.toString());
  url.searchParams.set('w', props.width.toString());

  if (props.height) {
    url.searchParams.set('h', props.height.toString());

    if (props.faces) {
      url.searchParams.set('fit', 'facearea');
      url.searchParams.set('crop', 'faces');
      url.searchParams.set('facepad', '2');
    } else {
      url.searchParams.set('fit', 'crop');

      if (props.entropy) {
        url.searchParams.set('crop', 'entropy');
      }
    }
  }

  if (props.sharpen) {
    url.searchParams.set('sharp', '50');
  }

  return url.toString();
}

export default function ResponsiveImage(
  props: {
    width?: number;
    className?: string;
    round?: boolean;
    onClick?: () => void;
  } & TransformationProps,
) {
  const ref = useRef<HTMLImageElement>(null);

  return (
    <img
      ref={ref}
      src={build_responsive_url(props, 1)}
      srcSet={`${build_responsive_url(props, 1)}, ${build_responsive_url(props, 2)} 2x, ${build_responsive_url(
        props,
        3,
      )} 3x`}
      width={props.width}
      onClick={props.onClick}
      className={classNames(props.className, { 'rounded-theme overflow-hidden': props.round })}
    />
  );
}
